import { useState } from 'react';
import { useParams } from 'react-router-dom';

import { EMPTY_ITEMS, EQueryFetchPolicy, SHOW_FETCH_MORE_LOADER } from 'constants/common';
import {
  CardSchema,
  CardTypeOptions,
  CardListObjectType,
  CardFilter,
  ECardSortingOptions,
  ImageSetSchema,
  useGetAllPicksCoverQuery,
  useGetUserCardsIdQuery,
  useGetUserCardsQuery,
  useGetProfileListsQuery,
  useGetUserRecentCardQuery,
} from 'constants/graphqlTypes';
import { useGetUserId } from 'graphQL/profile/hooks';
import { useGetSpotlights } from 'graphQL/spotlights/hook';
import { getTitlesForCover } from 'components/Collection/helpers';

import {
  IUseAllPicksCover,
  IUseAllPicksCoverArgs,
  IUseGetProfileListsArgs,
  IUseGetProfileLists,
  IUseGetUserCards,
} from './models';
import { updateUserCards } from './helpers';

interface IUseGetUserCardsParams {
  type?: CardTypeOptions;
  limit?: number;
  excludedIds?: string[];
  sorting?: ECardSortingOptions;
  includeQuestionResponse?: boolean;
  customUserId?: string;
  customSkip?: boolean;
  withImage?: boolean;
}

export const useGetUserCards = (params?: IUseGetUserCardsParams): IUseGetUserCards => {
  const [wasCompleted, setWasCompleted] = useState(false);
  const { type, limit, excludedIds, sorting, includeQuestionResponse, customUserId, customSkip, withImage } =
    params ?? {};
  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);

  const { data, fetchMore, loading } = useGetUserCardsQuery({
    variables: {
      id: customUserId ?? userId ?? '',
      offset: 0,
      limit,
      type,
      excludedIds,
      sorting,
      includeQuestionResponse: !!includeQuestionResponse,
      withImage,
    },
    onCompleted: () => setWasCompleted(true),
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: (!userId && !customUserId) || customSkip,
  });
  const { items, totalCount } = data?.allUsers?.items[0]?.pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return {
    recentCards: items,
    loadMore,
    totalCount,
    loading,
    wasCompleted,
  };
};

export const useGetUserRecentPick = (): { recentPick?: CardSchema; hasSpotlights: boolean } => {
  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);
  const { hasSpotlights, cards } = useGetSpotlights();

  const { data } = useGetUserRecentCardQuery({
    variables: {
      userId: userId ?? '',
      ...(hasSpotlights && { excludedIds: cards.map((card) => card.cardId) }),
    },
    fetchPolicy: EQueryFetchPolicy.NetworkOnly,
    skip: !userId,
  });

  return { recentPick: data?.allUsers?.items?.[0]?.pickedCards?.items?.[0], hasSpotlights };
};

export const useGetProfileLists = ({
  limit,
  privacy,
  contentTypeIn = [CardTypeOptions.Collection],
  withPrivacy,
  skip,
  customUserId,
}: IUseGetProfileListsArgs): IUseGetProfileLists => {
  const [isQueryCalled, setIsQueryCalled] = useState(false);
  const { profileName } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(profileName);
  const filters: CardFilter = {
    and: [
      { typeIn: contentTypeIn },
      ...(withPrivacy !== undefined ? [{ withPrivacy }] : []),
      ...(privacy !== undefined ? [{ privacy }] : []),
    ],
  };

  const { data, fetchMore, loading } = useGetProfileListsQuery({
    variables: {
      id: customUserId ?? userId ?? '',
      offset: 0,
      limit,
      filters,
    },
    onCompleted: () => setIsQueryCalled(true),
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    ...SHOW_FETCH_MORE_LOADER,
    skip: skip ?? (!userId && !customUserId),
  });

  const { items, totalCount } = data?.allUsers?.items[0]?.pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return { lists: items, loadMore, totalCount, loading, isQueryCalled };
};

export const useGetUserCardsId = (): { ids: string[]; loadMore: () => void } => {
  const { profileName: username } = useParams<{ profileName: string }>();
  const { userId } = useGetUserId(username);
  const { data, fetchMore, loading } = useGetUserCardsIdQuery({
    variables: { id: userId ?? '' },
    ...SHOW_FETCH_MORE_LOADER,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
    skip: !userId,
  });

  const { items, totalCount }: CardListObjectType = data?.allUsers?.items[0].pickedCards ?? EMPTY_ITEMS;

  const loadMore = () =>
    items.length < totalCount &&
    !loading &&
    fetchMore({
      variables: { offset: items.length },
      updateQuery: (prev, { fetchMoreResult }) => updateUserCards(prev, fetchMoreResult),
    });

  return { ids: items.map(({ id }) => id), loadMore };
};

export const useAllPicksCover = (args?: IUseAllPicksCoverArgs): IUseAllPicksCover => {
  const { customUserId, skipQuery } = args ?? {};
  const { profileName: username } = useParams<{ profileName?: string }>();
  const { userId } = useGetUserId(username ?? '');

  const { data } = useGetAllPicksCoverQuery({
    variables: { id: customUserId ?? userId },
    skip: (!userId && !customUserId) || skipQuery,
    fetchPolicy: EQueryFetchPolicy.CacheAndNetwork,
  });

  const images =
    data?.allUsers?.items[0].allPicksCover?.items?.reduce(
      (acc: ImageSetSchema[], item) => (item?.__typename === 'ImageSetSchema' && item ? acc.concat(item) : acc),
      []
    ) ?? [];

  const titles = getTitlesForCover(data?.allUsers?.items[0].allPicksCover?.items || []);

  return { images, titles };
};
